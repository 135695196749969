<template>
  <div class="container detail guide">
    <div class="top">
      <div class="inner">
        <h2>이용안내</h2>
      </div>
    </div>
    <div class="base">
      <section>
        <article class="inner">
          <div>
            <h4>입력 가격 적용 방식</h4>
            <p>
              1. 얼마니의 가격은 회원들이 입력하는 가격들을 누적평균 가격으로
              계산됩니다.
            </p>
            <p>
              2. 시작 가격은 시중 판매가 또는 저희 얼마니가 정하는 가격으로
              조사가 시작됩니다.
            </p>
            <p>
              3. 현재가(평균가) 보다 30% 이상 또는 30% 이하의 가격 입력 시 평균
              가격에 반영 되지 않습니다. <br />
              다만, 최종 근접 가격회원 선정 시 반영되므로 원하시는 가격을 소신껏
              입력하시면 됩니다.
            </p>
            <span
              >* 평균가격 반영 제한 범위(상.하위 %)는 설문아이템별 다를 수
              있습니다.</span
            >
            <span>예시)</span>
            <div class="table">
              <table class="col">
                <tr>
                  <th>현재가격(평균가)</th>
                  <td>11,000원</td>
                  <td>11,000원</td>
                  <td>12,000원</td>
                </tr>
                <tr>
                  <th>회원 입력가격</th>
                  <td>12,000원(회원A)</td>
                  <td>15,000원(회원B)</td>
                  <td>13,000원(회원C)</td>
                </tr>
              </table>
            </div>
            <div class="table">
              <table class="row">
                <tr>
                  <th>현재가격(평균가)</th>
                  <th>회원 입력가격</th>
                </tr>
                <tr>
                  <td>11,000원</td>
                  <td>12,000원(회원A)</td>
                </tr>
                <tr>
                  <td>11,000원</td>
                  <td>15,000원(회원B)</td>
                </tr>
                <tr>
                  <td>11,000원</td>
                  <td>13,000원(회원C)</td>
                </tr>
              </table>
            </div>
            <span>
              회원B의 입력가격이 이전 평균가격 11,000원보다 30% 이상으로
              누적평균가격에 적용되지 않습니다.</span
            >
            <p>
              4. 아이템별 희망 구매가격 입력 횟수는 최대 5회입니다. <br />
              최종 가격에 가장 근접한 가격제시 회원선정시 선착순으로 선정되므로
              최초(1차) 가격 입력에 신중하시기를 권장 드립니다.
            </p>
          </div>
          <div>
            <h4>근접가격 선착순 선정 방법</h4>
            <p>
              &#183; 각 아이템 별 최종 가격에 근접한 가격을 입력한 회원에게는
              선착순 쿠폰 등 혜택을 드립니다.
            </p>
            <span>* 설문 아이템별 혜택이 없을 수 있습니다.</span>
            <p>
              &#183; 최종가격에 가장 근접한 회원을 선착순으로 선정하여 혜택을
              드립니다.
            </p>
            <span>
              예시) 1차 입력기준 최종가격에 가장 근접한 회원 선착순 100명 →
              불만족시 2차, 3차 순으로 입력기준 선정됩니다. 회원별 가장 먼저
              입력한 가격부터 고려되므로 되도록 1차 입력시 신중하고 소신있게
              가격을 입력해주십시오.
            </span>
            <span>* 이와 다른 추천 및 선정 방식이 적용될 수 있습니다.</span>
          </div>
          <div>
            <h4>회원 등급 및 혜택</h4>
            <p>
              * 등급별 혜택은 사정에 따라 회원님에 별도 고지 없이 변경될 수
              있음을 양해 부탁드립니다. 다만, 최대한 많은 혜택을 드리고자
              노력하겠습니다.
            </p>
            <div class="table">
              <!-- <span>예시)</span> -->
              <table class="col">
                <tr>
                  <th>등급</th>
                  <td>실버</td>
                  <td>골드</td>
                  <td>다이아몬드</td>
                </tr>
                <tr>
                  <th>누적포인트</th>
                  <td>50</td>
                  <td>100</td>
                  <td>200</td>
                </tr>
                <tr>
                  <th>혜택</th>
                  <td>준비중</td>
                  <td>준비중</td>
                  <td>준비중</td>
                </tr>
              </table>
            </div>
            <div class="table">
              <!-- <span>예시)</span> -->
              <table class="row">
                <tr>
                  <th>등급</th>
                  <th>누적포인트</th>
                  <th>혜택</th>
                </tr>
                <tr>
                  <td>실버</td>
                  <td>50</td>
                  <td>준비중</td>
                </tr>
                <tr>
                  <td>골드</td>
                  <td>100</td>
                  <td>준비중</td>
                </tr>
                <tr>
                  <td>다이아몬드</td>
                  <td>200</td>
                  <td>준비중</td>
                </tr>
              </table>
            </div>
            <span
              >* 평균가격 반영 제한 범위(상.하위 %)는 설문아이템별 다를 수
              있습니다.</span
            >
            <div class="box card">
              <div class="point">5P</div>
              <div class="click">
                <em>* 5포인트</em>
                <span class="material-icons"> chevron_right</span>
              </div>
              <div class="img">
                <img src="@/assets/images/item.jpg" alt="" />
              </div>
              <div class="tit">
                <span>테크/가전</span>
                <h4>필립스 에어 프라이어 6.2L HD9270</h4>
              </div>
              <div class="price">
                <div class="flexB">
                  <em>시작가</em>
                  <span>300,000원</span>
                </div>
                <div class="flexB">
                  <em class="primary">현재가</em>
                  <span class="primary">253,340원</span>
                </div>
                <div class="line"></div>
              </div>
              <div class="live">
                <span>53,340명</span>
                <span>43분 남음</span>
              </div>
              <div class="coupon">
                <p>* 11번가 구매시 상위 10% 할인쿠폰 30% 지급</p>
              </div>
            </div>
          </div>
        </article>
      </section>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchPostDetail, createPostLike } from "@/api/post";
import { createFunding, fundingCancel } from "@/api/funding";
import { uploadFile } from "@/api/file";
import { format } from "@/mixins/format";

export default {
  mixins: [format],

  data() {
    return {
      moment: moment,
      modalVisible: false,
      contactVisible: false,
      fundingVisible: false,
      postId: "",
      title: "",
      targetAmount: 0,
      totalAmount: 0,
      fundingAmount: 0,
      fundingCount: 0,
      fundingRate: 0,
      deadLine: 0,
      endAt: "",
      startAt: "",
      likesCount: 0,
      isFunding: true,
      images: [],
      likes: [],
      tel: "",
      company: "",
    };
  },
  computed: {
    ...mapState(["userId", "isLogin"]),
  },
  mounted() {
    this.postId = this.$route.query.id;
    this.getPost();
  },
  methods: {
    getPost() {
      fetchPostDetail(this.postId).then((res) => {
        if (res.data.status == 200) {
          this.title = res.data.data.title;
          this.company = res.data.data.company;
          this.tel = res.data.data.tel;
          this.targetAmount = res.data.data.targetAmount;
          this.totalAmount = res.data.data.totalAmount;
          this.fundingCount = res.data.data.funding.length;
          this.fundingAmount = res.data.data.fundingAmount;
          this.images = res.data.data.images;
          this.startAt = res.data.data.startAt;
          this.endAt = res.data.data.endAt;
          this.isFunding =
            res.data.data.funding.indexOf(this.userId) > -1 ? false : true;
          this.likesCount = res.data.data.likes.length;
          this.likes = res.data.data.likes;
          this.deadLine = Math.ceil(
            (new Date(this.endAt).getTime() - new Date().getTime()) /
              (1000 * 60 * 60 * 24)
          );
          // this.deadLine = moment(this.endAt)
          //   .format("YYYY.MM.DD")
          //   .diff(moment(new Date().format("YYYY.MM.DD")), "day");
          this.fundingRate = (this.totalAmount / this.targetAmount) * 100;
        }
      });
    },

    handleLike() {
      if (!this.isLogin) {
        this.$router.push("/login");
        return alert("로그인 후 이용해세요.");
      }
      let data = {
        postId: this.postId,
      };
      createPostLike(data).then((res) => {
        if (res.data.status == 200) {
          this.likesCount = res.data.data.likes.length;
          this.likes = res.data.data.likes;
        } else {
          return alert(res.data.message);
        }
      });
    },
    sendFile() {
      this.file = this.$refs.images.files[0];
      if (this.file == undefined) {
        return;
      }
      let formdata = new FormData();
      formdata.append("file", this.file);
      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.file = "";
            this.images.push(res.data.url);
          } else {
            return alert(res.data.message);
          }
        } else {
          return alert("서버오류 입니다. 다시 파일을 업로드 해주세요.");
        }
      });
    },
    handleFunding() {
      if (!this.isLogin) {
        this.$router.push("/login");
        return alert("로그인 후 이용해세요.");
      }
      let data = {
        postId: this.postId,
      };
      createFunding(data).then((res) => {
        if (res.data.status == 200) {
          this.modalVisible = true;
          this.fundingVisible = true;
          this.isFunding = false;
          this.$store.dispatch("getUserFundingList", this.userId);
        } else {
          return alert(res.data.message);
        }
      });
    },
    handleFundingCancel() {
      if (!this.isLogin) {
        this.$router.push("/login");
        return alert("로그인 후 이용해세요.");
      }
      let data = {
        postId: this.postId,
        fundingId: this.postId,
      };
      fundingCancel(data).then((res) => {
        if (res.data.status == 200) {
          this.isFunding = true;
          this.$store.dispatch("getUserFundingList", this.userId);
        } else {
          return alert(res.data.message);
        }
      });
    },
    close() {
      this.modalVisible = false;
      this.contactVisible = false;
      this.fundingVisible = false;
    },
    handleContactModal() {
      this.modalVisible = true;
      this.contactVisible = true;
    },
    handleUrlCopy() {
      var dummy = document.createElement("input");
      var text = location.href;

      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      this.$alert("원하는 곳에 붙여넣기 해주세요.", "URL이 복사되었습니다.", {
        confirmButtonText: "OK",
        center: true,
      });
    },
  },
};
</script>
